
.markdown{
    line-height: 30px;
}
.markdown>h2 {
    font-size: 25px;
    font-weight: 600;
}

.markdown>h3 {
    font-size: 18px;
    font-weight: 600;
}
.markdown>p {
    font-size: 16px;
    font-weight: 500;
}