.App {
  text-align: center;
}

/* label {
  font-size: 16px;
  font-weight: 500;
}

input {
  font-weight: 500;
  padding: 0px 20px;
  font-size: 16px;
  border: 2px solid black;
  border-radius: 5px;
  outline: none;
}

button {
  background-color: transparent;
  border: 0;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  color: black;
}

span {
  font-weight: 500;
} */
